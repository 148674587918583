<template>
    <section>
        <modal ref="modalCambioPrecio" titulo="Detalle del producto" :tamano="producto.kit ? 'modal-xl' : 'modal-lg'" :cargado-modal="cargando" no-aceptar adicional="Actualizar" @adicional="actualizar_cantidad">
            <div class="row mx-0">
                <div class="col-auto">
                    <img :src="producto.imagen" class="obj-cover br-12 border p-2 cr-pointer" width="169" height="169" @click="getImages" />
                    <div class="row mx-0 my-3 justify-center">
                        <el-tooltip placement="bottom" content="Reemplazar" effect="light">
                            <div class="col-auto text-center br-10 bg-light-f5 cr-pointer p-1 px-1 border shadow mx-2" @click="reemplazar">
                                <i class="icon-swap-horizontal-bold f-20 text-general" />
                            </div>
                        </el-tooltip>
                        <el-tooltip placement="bottom" content="Eliminar" effect="light">
                            <div class="col-auto text-center br-10 bg-light-f5 cr-pointer p-1 px-1 border shadow mx-2" @click="eliminarProducto">
                                <i class="icon-delete-trash f-20 text-general" />
                            </div>
                        </el-tooltip>
                        <el-tooltip v-show="$can('botones_abastecimiento_solicitar_cambio_precio')" placement="bottom" content="Solicitar Cambio de Precio" effect="light">
                            <div class="col-auto text-center br-10 bg-light-f5 cr-pointer p-1 px-1 border shadow mx-2" @click="cambiarPecio">
                                <i class="icon-dots-vertical f-20 text-general" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div :class="producto.kit ? 'col-6' : 'col'">
                    <p class="text-general f-15 f-600">
                        {{ producto.nombre }}
                    </p>
                    <div v-if="sugerencia.activa">
                        <div class="row mx-0 my-2">
                            <p class="col-auto px-0 text-general f-14 f-600">
                                {{ producto.precio_sugerido }}
                            </p>
                            <p class="col-auto text-gris descuento f-600 f-14">
                                {{ producto.precio }}
                            </p>
                        </div>
                        <div class="row mx-0 align-items-center">
                            <div class="bg-general3 text-white px-3 br-12 f-12">
                                {{ sugerencia.porcentaje }} ({{ sugerencia.estado == 1 ? 'Cambio de precio pendiente' : sugerencia.estado == 2 ? 'Aprobado' : sugerencia.estado == 3 ? 'Aprobado' : 'Rechazado' }})
                            </div>
                            <div class="bg-light-f5 text-general2 br-12 px-3 shadow mx-3 cr-pointer f-12" @click="verSolicitud">
                                Ver Solicitud
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row mx-0 my-2">
                            <p class="col-auto px-0 text-general f-14 f-600">
                                {{ promocion.activa ? promocion.valor : producto.precio }}
                            </p>
                            <p v-show="promocion.activa" class="col-auto text-gris descuento f-600 f-14">
                                {{ producto.precio }}
                            </p>
                        </div>
                        <div v-show="promocion.activa" class="row mx-0 align-items-center">
                            <div class="bg-general text-white px-3 br-12 f-12">
                                {{ promocion.texto }}
                            </div>
                        </div>
                    </div>
                    <div v-if="precio_nuevo.estado" class="row mx-0 align-items-center">
                        <div class="f-12">
                            <i class="icon-attention-alt text-danger f-20" />
                            <span class="text-general f-14">Precio desactualizado: {{ convertMoney(precio_nuevo.valor,idm_moneda) }}</span>
                        </div>
                        <div class="bg-light-f5 text-general2 br-12 px-3 shadow mx-3 cr-pointer f-12" @click="openModalConfirmarActualizacionPrecio">
                            Actualizar
                        </div>
                    </div>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Barcode: </b> {{ producto.barcode != null ? producto.barcode : 'No registra' }}
                        <b class="f-500 ml-3">SKU: </b> {{ producto.sku != null ? producto.sku : 'No registra' }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Unidades disponibles: </b> {{ agregarSeparadoresNumero(producto.unidades_disponibles) }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Venta sin stock: </b> {{ producto.venta_sin_stock == 1 ? 'Si' : 'No' }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Presentacion: </b> {{ producto.presentacion }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500"> Unidad de Medida: </b> {{ producto.unidad }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Venta mínima:</b> {{ producto.cantidad_minima }} {{ producto.unidad }}
                    </p>
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general col-auto px-0 f-14">
                            <b> Cantidad Pedida:</b> {{ producto.cantidad_pedida }}
                        </p>
                        <p class="text-general col-auto f-14">
                            <b> Valor Total:</b> {{ producto.total_pedida }}
                        </p>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col border br-12 py-2 d-middle">
                            <div class="col">
                                <p class="f-15 text-general">
                                    Editar la Cantidad
                                </p>
                                <el-input-number
                                v-model="model.cantidad"
                                class="transparent-input"
                                :min="minimo"
                                :step="salto"
                                :step-strictly="entero"
                                :precision="presicion"
                                style="width:160px;"
                                />
                            </div>
                            <div class="col pr-0 tres-puntos">
                                <p class="text-general f-15 tres-puntos">
                                    <b>
                                        Nuevo Total:
                                    </b>
                                    {{ convertMoney(total_nuevo, idm_moneda) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-3 align-items-end justify-content-between">
                        <div class="col-3 text-center">
                            <p class="text-general f-13 justify-center text-center">Inventario proveedor</p>
                            <el-checkbox v-model="producto.inventario_proveedor" :disabled="!$can('boton_abastecimiento_inventario_proveedor')" :true-label="1" :false-label="0" class="check-red" @change="updateProveedor" />
                        </div>
                        <div class="col-7 px-1">
                            <p class="text-general f-13 pl-2">Seleccione Proveedor (opcional)</p>
                            <el-select v-model="producto.id_proveedor" clearable placeholder="Seleccione Proveedor" size="small" class="w-100" @change="updateProveedor">
                                <el-option
                                v-for="item in provedores"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row mx-auto px-0 mt-1">
                        <div class="w-100">
                            <label class="f-13 pl-3 text-general">Comentario</label>
                            <el-input v-model="comentario" type="textarea" :rows="3" show-word-limit maxlength="200" />
                        </div>
                    </div>
                </div>

                <div v-show="producto.kit" class="col">
                    <productos-kits ref="detalleKit" />
                </div>
            </div>
        </modal>
        <modal-solicitud-cambio-precio ref="SolicitudCambioPrecio" />
        <modal-solicitar-cambio-precio ref="SolicitarCambioPrecio" />
        <modal-remplazar-producto ref="modalRemplazarProducto" @update="$emit('update')" />
        <modal-imagenes ref="modalImagnes" @cerrar="$refs.modalCambioPrecio.toggle()" />
        <modalSiNo
        ref="modalActualizarPrecio"
        titulo="Actualizar Precio"
        mensaje="¿Desea actualizar el precio del producto?"
        icon="money"
        @accion="actualizar_precio"
        />
    </section>
</template>

<script>
import axios from 'axios'
import Pedidos from '~/services/pedidos/pedidos-admin'
import Productos from '~/services/productos/productos'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalSolicitudCambioPrecio: () => import('./modalSolicitudCambioPrecio'),
        modalSolicitarCambioPrecio: () => import('./modalSugerenciaPrecio'),
        modalRemplazarProducto: () => import('./modalRemplazarProducto')
    },
    data(){
        return {
            ver:true,
            minimo:0,
            salto:0,
            entero:false,
            presicion:0,
            comentario:'',

            datos:{},
            producto:{},

            model:{
                cantidad:'',
            },
            sugerencia:{
                activa:false,
                estado:0,
                unidad_sugerida:0,
                porcentaje:''
            },
            promocion:{
                activa:false,
                texto:'',
                valor:0
            },
            precio_nuevo:{
                estado:false,
                valor:0
            },
            provedores:[],
            productosKits:[],
            productoOriginal:{},
            cargando:false

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
        idm_moneda(){
            return this.pedido.idm_moneda
        },
        total_nuevo(){
            return this.producto.precio_final * this.model.cantidad
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCambioPrecio.toggle();
        },
        limpiar(){

            this.model = {
                cantidad:0,
            }


            this.minimo = 0
            this.salto = 0
            this.entero= false
            this.presicion =0
            this.comentario = ''

            this.producto = {
                id:0,
                nombre:'',
                descripcion:'',
                comentario:'',
                imagen:'',
                precio: 0,
                precio_final: 0,
                presentacion:'',
                unidad:'',
                cantidad_minima:0,
                cantidad_pedida: 0,
                total_pedida: 0
            }

            this.sugerencia={
                activa:false,
                estado:0,
                unidad_sugerida:0,
                porcentaje:''
            }

            this.promocion={
                activa:false,
                texto:'',
                valor:0
            }
            this.precio_nuevo={
                estado:false,
                valor:0
            },
            this.productosKits=[]
        },
        async datos_basicos(prod){
            this.datos = prod
            this.limpiar()

            // console.log(prod);
            this.cargar_datos(prod)
            if(prod.sugerido == 0){
                await this.buscar_actualizacion()

            }else if(prod.sugerido && (prod.sugerido.estado == 1 || prod.sugerido.estado == 4)){

                await this.buscar_actualizacion()
            }

            if(prod.kit){
                await this.getProductosKit()
            }

            if(this.provedores.length === 0){
                this.select_proveedores()
            }
            this.$refs.modalCambioPrecio.toggle()
        },
        async actualizar_cantidad(){
            try {
                this.cargando = true
                let form = {
                    id_producto:this.producto.id,
                    cantidad:this.model.cantidad,
                    comentario:this.comentario
                }
                const {data} = await Pedidos.change_producto(this.producto.id_pedido_producto, form)
                this.notificacion('Registro Actualizado','','success')

                this.$emit('update')   //recalculamos todos los datos
                this.toggle()

            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false
            }
        },
        async buscar_actualizacion(id_producto){
            try {

                const {data} = await Pedidos.nuevo_valor_producto(this.producto.id, this.pedido.id_cedis)
                if(data.valor == null) return
                let value = 0
                if(this.pedido.impuestos){
                    value = data.valor / (1 + this.producto.porcentaje_impuesto / 100)
                    if(value != this.producto.precio_final){
                        this.precio_nuevo.estado = true
                        this.precio_nuevo.valor = value
                    }
                }else{
                    if(data.valor != this.producto.precio_final){
                        this.precio_nuevo.estado = true
                        this.precio_nuevo.valor = data.valor
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        openModalConfirmarActualizacionPrecio(){
            this.$refs.modalCambioPrecio.toggle()
            this.$refs.modalActualizarPrecio.toggle()
            return
        },
        async actualizar_precio(res){
            if(!res){
                this.$refs.modalActualizarPrecio.toggle()
                return
            }
            try {
                let form ={
                    id_producto:this.producto.id,
                    id_cedis:this.pedido.id_cedis
                }
                const {data} = await Pedidos.actualizar_precio_producto(this.producto.id_pedido_producto, form)
                this.precio_nuevo.estado = false
                this.cargar_datos(data.registro)
                this.$emit('update')
                this.$refs.modalActualizarPrecio.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        cargar_datos(prod){
            //console.log(prod);
            this.productoOriginal = prod
            let decimalesFrancion = 0
            if(prod.producto.cantidad_tipo === 1){
                decimalesFrancion = 0
            } else{
                decimalesFrancion = 2
            }

            this.minimo = parseFloat(prod.compra_minima)
            this.salto = parseFloat(prod.producto.cantidad_minima)
            this.entero = prod.producto.cantidad_tipo === 1
            this.presicion = prod.producto.cantidad_tipo === 1 ? 0 : 2
            this.model.cantidad = parseFloat(prod.cantidad)

            this.comentario = prod.comentario
            this.producto = {
                id:prod.producto.id,
                kit:prod.kit,
                id_pedido_producto:prod.id,
                nombre:prod.producto.nombre,
                detalle_desconocido:prod.producto.detalle_desconocido,
                descripcion:prod.producto.descripcion,
                imagen:prod.producto.imagen,
                precio: this.convertMoney(prod.unidad_teorica,this.idm_moneda),
                precio_final: prod.unidad_final,
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,decimalesFrancion),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,decimalesFrancion) + prod.producto.unidad,
                total_pedida: this.convertMoney(prod.total_final,this.idm_moneda),
                porcentaje_impuesto: prod.porcentaje_impuesto,
                id_proveedor: prod.id_proveedor,
                inventario_proveedor: prod.inventario_proveedor,
                barcode: prod.barcode,
                sku: prod.sku,
                unidades_disponibles: prod.unidades_disponibles,
                venta_sin_stock: prod.venta_sin_stock

            }

            if(prod.sugerido){
                this.producto.precio_sugerido = this.convertMoney(prod.sugerido.unidad_sugerida, this.idm_moneda)
                this.sugerencia.activa = true
                this.sugerencia.estado = prod.sugerido.estado
                this.sugerencia.unidad_sugerida =  this.convertMoney(prod.sugerido.unidad_sugerida,this.idm_moneda)
                this.sugerencia.porcentaje = prod.sugerido.porcentaje
            }

            if(prod.promocion){
                this.promocion.activa = true
                this.promocion.texto = prod.data_promocion.texto
                this.promocion.valor =  this.convertMoney(prod.unidad_final,this.idm_moneda)
            }
        },
        verSolicitud(){
            this.toggle()
            this.$refs.SolicitudCambioPrecio.openSolicitud(this.datos);
        },
        reemplazar(){
            this.toggle()
            this.$refs.modalRemplazarProducto.load_data(this.datos);
        },
        eliminarProducto(){
            this.toggle()
            this.$emit('eliminar',this.datos.id)
        },
        async select_proveedores(){

            const {data} = await axios(`proveedores/select?id_cedis=${this.pedido.id_cedis}`)
            this.provedores = data.provedores
        },
        async updateProveedor(){
            try {

                let form = {
                    id_pp:this.producto.id_pedido_producto,
                    id_proveedor:this.producto.id_proveedor == '' ? null : this.producto.id_proveedor,
                    inventario_proveedor:this.producto.inventario_proveedor,
                }
                const {data} = await Pedidos.actualizar_proveedor(form)
                this.notificacion('','Proveedor actualizado','success')

            } catch (e){
                this.error_catch(e)
            }

        },
        async getImages(){

            const {data} = await Productos.getImages(this.producto.id)
            if(data.imagenes.length === 0) return
            this.$refs.modalCambioPrecio.toggle()
            this.$refs.modalImagnes.toggle(data.imagenes)
        },
        async getProductosKit(){
            await this.$refs.detalleKit.getProductosKit(this.producto.id, this.pedido.id_cedis)
        },
        cambiarPecio(){
            this.$refs.modalCambioPrecio.toggle()
            this.$refs.SolicitarCambioPrecio.toggle(this.productoOriginal)
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>
